import { EncabezadoAdmin, BotonMenu } from './ComponenetesAdmin/Encabezado'

function AdminVentas() {
    return (
        <div>
            <EncabezadoAdmin />
            <div>
                <h1>Compras</h1>
            </div>
        </div>
    )
}

export default AdminVentas