import React, { useState, useEffect } from 'react';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';
import { Icon } from '@iconify/react';
import { NavLink, useParams } from 'react-router-dom';
import { Image } from "antd";
import CustomDropdown from '../components/CustomDropdown.jsx';
import ToggleDiv from '../components/ToggleDiv.jsx';
import { getProducto, agregarAlCarrito, obtenerRecomendaciones } from '../api/auth.js';
import { useAuth } from "../context/AuthContext.jsx";
import Swal from 'sweetalert2';

const options = [
    { key: '1', label: '1 unidad' },
    { key: '2', label: '2 unidades' },
    { key: '3', label: '3 unidades' },
    { key: '4', label: '4 unidades' },
    { key: '5', label: '5 unidades' },
    { key: '6', label: '6 unidades' },
];

const ResenaProducto = () => {
    const { id } = useParams();
    const { user, isAuthenticated } = useAuth();

    const [loading, setLoading] = useState(true);
    const [producto, setProducto] = useState({});
    const [cantidad, setCantidad] = useState(1);
    const [recommendations, setRecommendations] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await getProducto(id);
            setProducto(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener datos:', error);
            setLoading(false);
            Swal.fire({
                title: "Error",
                text: "Hubo un problema al cargar los datos del producto. Por favor, intenta nuevamente.",
                icon: "error",
            });
        }
    };

    const handleAgregarAlCarrito = async () => {
        if (!isAuthenticated) {
            Swal.fire({
                title: "Inicia sesión",
                text: "Debes iniciar sesión para agregar productos al carrito.",
                icon: "warning",
            });
            return;
        }

        try {
            await agregarAlCarrito(user.id, producto._id, cantidad);
            Swal.fire({
                title: "Producto agregado",
                text: "Producto agregado al carrito exitosamente.",
                icon: "success",
            });

            const recomendacionesResponse = await obtenerRecomendaciones();
            setRecommendations(recomendacionesResponse.data);
            console.log(recomendacionesResponse);

        } catch (error) {
            console.error('Error al agregar producto al carrito:', error);
            Swal.fire({
                title: "Error",
                text: "Hubo un error al agregar el producto al carrito. Por favor, intenta nuevamente.",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const toggleDescription = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <HeaderInicio />
            <div className="w-auto h-auto">
                {loading ? (
                    <div className="flex flex-col bg-neutral-300 mx-auto my-5 animate-pulse rounded-xl p-4 gap-4 justify-center w-4/5 h-auto">
                        <div className="bg-neutral-400/50 w-full h-32 animate-pulse rounded-md"></div>
                        <div className="flex flex-col gap-2">
                            <div className="bg-neutral-400/50 w-full h-4 animate-pulse rounded-md"></div>
                            <div className="bg-neutral-400/50 w-4/5 h-4 animate-pulse rounded-md"></div>
                            <div className="bg-neutral-400/50 w-full h-4 animate-pulse rounded-md"></div>
                            <div className="bg-neutral-400/50 w-2/4 h-4 animate-pulse rounded-md"></div>
                        </div>
                    </div>
                ) : (
                    <div className="mx-auto w-4/5 max-w-4xl overflow-hidden grid md:flex border rounded border-2 bg-white my-5">
                        {producto && (
                            <div className="flex flex-col md:flex-row md:items-center w-full">
                                <div className="md:w-1/2 p-4">
                                    <Image
                                        className="h-auto max-w-full"
                                        src={producto.Imagen}
                                        alt={producto.NameProducto}
                                    />
                                </div>

                                <div className="md:w-1/2 p-5">
                                    <div className="flex justify-between items-center">
                                        <h2 className="text-2xl font-bold">{producto.NameProducto}</h2>
                                        <div className="flex items-center">
                                            <NavLink to="">
                                                <Icon icon="mdi:heart-outline" className="text-black hover:text-blue-500 transition-all duration-300 ease-in-out" />
                                            </NavLink>
                                        </div>
                                    </div>

                                    <h2 className="text-2xl mt-2">${producto.Precio}</h2>
                                    <p className={`text-base w-auto mt-4 text-gray-500 ${expanded ? '' : 'line-clamp-3'}`}>
                                            {producto.Descripcion}
                                        </p>
                                        <button onClick={toggleDescription} className="text-blue-500">
                                            {expanded ? 'Ver menos' : 'Ver más'}
                                        </button>
                                    <div className="flex mt-4">
                                        <CustomDropdown options={options} onSelect={(key) => setCantidad(Number(key))} />
                                        <p className="ml-2 text-base">(+{producto.Existencias} disponibles)</p>
                                    </div>

                                    <div className="flex space-x-2 mt-4">
                                        <button type="submit" className="bg-[#3BA4F6] text-white rounded p-2 font-bold w-44 hover:bg-[#2587eb]">Comprar ahora</button>
                                        <button type="button" onClick={handleAgregarAlCarrito} className="bg-[#4db4b2] text-white rounded p-2 font-bold w-44 hover:bg-[#329696]">Agregar al carrito</button>
                                    </div>

                                    <div className="mt-4">
                                        <ToggleDiv title="Características" content={producto.Caracteristicas} />
                                        <ToggleDiv title="Categoría" content={producto.Categoria} />
                                        <ToggleDiv title="¿Qué incluye?" content={producto.Incluye} />
                                    </div>

                                    {recommendations.length > 0 && (
                                        <div className="mt-4">
                                            <h1 className="text-xl font-bold mb-2">Otros clientes también agregaron estos productos a su carrito</h1>
                                            <div className="flex overflow-x-auto space-x-4">
                                                {recommendations.map((recProduct) => (
                                                    <div key={recProduct._id} className="flex-shrink-0 w-40">
                                                        <NavLink to={`/resena-producto/${recProduct._id}`}>
                                                            <div className="card w-auto h-auto p-2 bg-gray-100 relative overflow-visible shadow-md">
                                                                <div className="card-img bg-white h-24 rounded-md transition-transform duration-300 hover:translate-y-3 w-auto h-auto">
                                                                    <img src={recProduct.Imagen} alt={recProduct.NameProducto} className="w-auto h-auto mx-auto" />
                                                                </div>
                                                                <div className="card-info pt-6 grid">
                                                                    <div className="flex w-auto justify-between">
                                                                        <p className="text-title font-bold text-lg overflow-hidden line-clamp-1">{recProduct.NameProducto}</p>
                                                                    </div>
                                                                    <p className="text-body text-sm overflow-hidden line-clamp-2">{recProduct.Descripcion}</p>
                                                                </div>
                                                                <div className="card-footer flex justify-between items-center pt-2 border-t border-gray-300">
                                                                    <span className="text-title font-bold">${recProduct.Precio}</span>
                                                                    <div className="card-button border border-gray-900 flex items-center justify-center p-1 rounded-full cursor-pointer transition duration-300 ease-in-out hover:border-sky-400 bg-white hover:bg-sky-400">
                                                                        <Icon icon="mdi:heart-outline" className="text-black" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <FooterInicio />
        </div>
    );
};

export default ResenaProducto;
