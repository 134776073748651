import React from 'react'
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';

const Botones = () => {
  return (
    <div>
      <HeaderInicio/>
      <div>
        <h1 className='tituloB'>Botones</h1>
      </div>
      <FooterInicio />

    </div>
  )
}

export default Botones