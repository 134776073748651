import React, { useState, useEffect } from 'react';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { agregarAlCarrito, agregarAFavoritos, obtenerFavoritos } from '../api/auth.js';
import { useAuth } from "../context/AuthContext.jsx";

const Productos = () => {
  const { user } = useAuth();
  const [productos, setProductos] = useState([]);
  const [favoritos, setFavoritos] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (user && user.id) {
      cargarFavoritos();
    }
    showData();
  }, [user]);

  const cargarFavoritos = async () => {
    try {
      const response = await obtenerFavoritos(user.id);
      setFavoritos(response.productos || []);
    } catch (error) {
      console.error('Error al cargar los favoritos:', error);
    }
  };

  const handleChange = (e) => {
    const userInput = e.target.value;
    setSearch(sanitizeInput(userInput));
  };

  const sanitizeInput = (input) => {
    return input.replace(/['"]/g, '');
  };

  const URL = "https://back-end-robopits.vercel.app/api/productos";

  const showData = async () => {
    try {
      const response = await axios.get(URL);
      setProductos(response.data);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleAgregarAlCarrito = async (productoId) => {
    try {
      if (!user || !user.id) {
        Swal.fire({
          title: "Inicia sesión",
          text: "Debes iniciar sesión para agregar productos al carrito.",
          icon: "warning",
        });
        return;
      }

      await agregarAlCarrito(user.id, productoId, 1);
      Swal.fire({
        title: "Producto agregado",
        text: "El producto se ha agregado al carrito exitosamente.",
        icon: "success",
      });
    } catch (error) {
      console.error('Error al agregar producto al carrito:', error);
      Swal.fire({
        title: "Error",
        text: "Hubo un problema al agregar el producto al carrito. Por favor, intenta nuevamente.",
        icon: "error",
      });
    }
  };

  const handleAgregarAFavoritos = async (productoId) => {
    try {
      if (!user || !user.id) {
        Swal.fire({
          title: "Inicia sesión",
          text: "Debes iniciar sesión para agregar productos a Favoritos.",
          icon: "warning",
        });
        return;
      }

      await agregarAFavoritos(user.id, productoId);
      Swal.fire({
        title: "Producto agregado a Favoritos",
        text: "El producto se ha agregado a tus Favoritos.",
        icon: "success",
      });

      cargarFavoritos(); // Recargar la lista de favoritos
    } catch (error) {
      console.error('Error al agregar producto a Favoritos:', error);
      Swal.fire({
        title: "Error",
        text: "Hubo un problema al agregar el producto a Favoritos. Por favor, intenta nuevamente.",
        icon: "error",
      });
    }
  };

  const esFavorito = (productoId) => {
    return favoritos.some(favorito => favorito._id === productoId);
  };

  const results = !search ? productos : productos.filter((dato) => dato.NameProducto.toLowerCase().includes(search.toLocaleLowerCase()));

  return (
    <div>
      <HeaderInicio />
      <div className='w-auto h-auto'>
        <div className='grid place-items-center w-auto'>
          <input
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Buscar"
            className="w-1/5 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className='flex justify-center my-10'>
          <div className='w-11/12 h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 place-items-center'>
            {results.map((producto) => (
              <div className='w-full h-full' key={producto._id}>
                <NavLink to={`/resena-producto/${producto._id}`}>
                  <div className="card w-auto h-auto p-2 bg-gray-100 relative overflow-visible shadow-md">
                    <div className="card-img bg-white h-24 rounded-md transition-transform duration-300 hover:translate-y-3 w-auto h-auto">
                      <img src={producto.Imagen} alt={producto.NameProducto} className='w-auto h-auto mx-auto' />
                    </div>
                    <div className="card-info pt-6 grid">
                      <div className='flex w-auto justify-between'>
                        <p className="text-title font-bold text-lg overflow-hidden line-clamp-1">{producto.NameProducto}</p>
                        <div 
                          className={`card-button border border-gray-900 flex items-center justify-center p-1 rounded-full cursor-pointer transition duration-300 ease-in-out ${esFavorito(producto._id) ? 'bg-red-500 hover:bg-red-600 text-white' : 'bg-white hover:bg-sky-400 text-black'}`}
                          onClick={(e) => {
                            e.preventDefault(); // Evitar que el NavLink se active al hacer clic en el icono
                            handleAgregarAFavoritos(producto._id);
                          }}
                        >
                          <Icon icon="mdi:heart-outline" className='text-black' />
                        </div>
                      </div>
                      <p className="text-body text-sm overflow-hidden line-clamp-2">{producto.Descripcion}</p>
                    </div>
                    <div className="card-footer flex justify-between items-center pt-2 border-t border-gray-300">
                      <span className="text-title font-bold">${producto.Precio}</span>
                      <div 
                        className="card-button border border-gray-900 flex items-center justify-center p-1 rounded-full cursor-pointer transition duration-300 ease-in-out hover:border-sky-400 bg-white hover:bg-sky-400"
                        onClick={(e) => {
                          e.preventDefault(); // Evitar que el NavLink se active al hacer clic en el icono
                          handleAgregarAlCarrito(producto._id);
                        }}
                      >
                        <Icon icon="mdi:cart-outline" className='text-black' />
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterInicio />
    </div>
  );
};

export default Productos;
