import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';
import { useAuth } from '../context/AuthContext.jsx';
import { obtenerPerfil, actualizarPerfil } from '../api/auth.js'; // Importa las funciones


const Perfil = () => {
    const { user } = useAuth();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPerfil = async () => {
            try {
                const response = await obtenerPerfil(user.id);
                setValue('nombre', response.data.nombre || '');
                setValue('email', response.data.email || '');
                setValue('telefono', response.data.telefono || '');
                setLoading(false);
            } catch (error) {
                setError('Error al obtener los datos del perfil');
                setLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "No se pudieron cargar los datos del perfil.",
                    icon: "error"
                });
            }
        };

        if (user) {
            fetchPerfil();
        }
    }, [user, setValue]);

    const onSubmit = async (data) => {
        try {
            await actualizarPerfil(user.id, data);
            Swal.fire({
                title: "Éxito",
                text: "Perfil actualizado correctamente.",
                icon: "success"
            });
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            Swal.fire({
                title: "Error",
                text: "Hubo un error al actualizar el perfil.",
                icon: "error"
            });
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <HeaderInicio />
            <div className="w-3/5 mx-auto p-4 grid">
                <h1 className="text-2xl font-bold mb-4">Mi Cuenta</h1>
                <div className="bg-white text-black rounded-lg p-6 shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Datos Personales</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                        <div>
                            <label className="block font-medium text-gray-700">Nombre</label>
                            <input
                                type="text"
                                name="nombre"
                                {...register('nombre', {
                                    required: 'El nombre es obligatorio',
                                    maxLength: {
                                        value: 10,
                                        message: 'El nombre no puede tener más de 10 caracteres'
                                    },
                                    minLength: {
                                        value: 5,
                                        message: 'El nombre no puede tener menos de 5 caracteres'
                                    },
                                    pattern: { 
                                        value: /^[a-zA-Z0-9_-]{5,10}$/, 
                                        message: 'El nombre de usuario debe tener entre 5 y 10 caracteres y solo puede contener letras, números, guiones bajos y guiones medios' 
                                    }
                                })}
                                className="w-full p-2 mt-1 bg-gray-100 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
                            />

                            {errors.nombre && <p className="text-red-600">{errors.nombre.message}</p>}
                        </div>
                        <div>
                            <label className="block font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                {...register('email', { required: 'El email es obligatorio' })}
                                className="w-full p-2 mt-1 bg-gray-100 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
                                readOnly
                            />
                            {errors.email && <p className="text-red-600">{errors.email.message}</p>}
                        </div>
                        <div>
                            <label className="block font-medium text-gray-700">Teléfono</label>
                            <input
                                type="tel"
                                name="telefono"
                                {...register('telefono', { pattern: { value: /^[0-9]{10}$/, message: 'Ingresa un número de teléfono válido de 10 dígitos' } })}
                                className="w-full p-2 mt-1 bg-gray-100 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
                            />
                            {errors.telefono && <p className="text-red-600">{errors.telefono.message}</p>}
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Guardar Cambios
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <FooterInicio />
        </div>
    );
};

export default Perfil;

