import React, { useState, useEffect } from 'react';
import { FooterInicio, HeaderInicio } from '../components/componentes-inicio';
import { obtenerFavoritos, eliminarDeFavoritos } from '../api/auth.js'; // Asegúrate de que las funciones estén correctamente importadas
import { useAuth } from '../context/AuthContext.jsx';
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';

const Favoritos = () => {
    const { user } = useAuth();
    const [favoritos, setFavoritos] = useState([]);

    useEffect(() => {
        if (user && user.id) {
            cargarFavoritos();
        }
    }, [user]);

    const cargarFavoritos = async () => {
        try {
            const response = await obtenerFavoritos(user.id);
            // Verifica la estructura de la respuesta
            if (response && response.productos) {
                setFavoritos(response.productos);
            } else {
                setFavoritos([]); // Si no hay favoritos, asegura que la lista esté vacía
            }
        } catch (error) {
            console.error('Error al cargar los favoritos:', error);
            Swal.fire({
                title: "Error",
                text: "Hubo un problema al cargar tus favoritos. Por favor, intenta nuevamente.",
                icon: "error",
            });
        }
    };

    const handleEliminarFavorito = async (productoId) => {
        try {
            await eliminarDeFavoritos(user.id, productoId);
            Swal.fire({
                title: "Eliminado",
                text: "El producto se ha eliminado de tus favoritos.",
                icon: "success",
            });
            setFavoritos(favoritos.filter(favorito => favorito._id !== productoId));
        } catch (error) {
            console.error('Error al eliminar el favorito:', error);
            Swal.fire({
                title: "Error",
                text: "Hubo un problema al eliminar el producto de tus favoritos. Por favor, intenta nuevamente.",
                icon: "error",
            });
        }
    };

    return (
        <div>
            <HeaderInicio />
            <div className="container grid mx-auto my-10">
                <h1 className="text-3xl font-bold mb-5">Mis Favoritos</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {favoritos.length > 0 ? (
                        favoritos.map((producto) => (
                            <div key={producto._id} className="bg-white rounded-lg shadow-md p-5">
                                <img src={producto.Imagen} alt={producto.NameProducto} className="h-40 w-full object-cover rounded-md" />
                                <div className="mt-4">
                                    <h2 className="text-xl font-bold">{producto.NameProducto}</h2>
                                    <p className="text-gray-600 text-sm mt-2">{producto.Descripcion}</p>
                                    <p className="text-lg font-bold mt-4">${producto.Precio}</p>
                                    <button
                                        onClick={() => handleEliminarFavorito(producto._id)}
                                        className="mt-4 bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 w-full flex justify-center items-center"
                                    >
                                        <Icon icon="mdi:trash-can-outline" className="mr-2" />
                                        Eliminar
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-600 col-span-full text-center">No tienes productos en favoritos.</p>
                    )}
                </div>
            </div>
            <FooterInicio />
        </div>
    );
};

export default Favoritos;
